import React from "react";
import PropTypes from "prop-types";

// <script async data-uid="ab26b68808" src="https://fabulous-painter-8604.ck.page/ab26b68808/index.js"></script>
class TechStackDiagram extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://fabulous-painter-8604.ck.page/ab26b68808/index.js";
    script.setAttribute("data-uid", "ab26b68808");
    script.async = true;
    const container = document.getElementById("__convertKitContainer");
    container.appendChild(script);
  }

  render() {
    const { title, description, content, contentComponent } = this.props;

    return (
      <div>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title has-text-white is-size-1">{title}</h1>
                    <h2 className="description has-text-white is-size-3">
                      {description}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <p style={{ marginBottom: "2rem" }}>
                  I worked with Stacey Broadwell over at TechRecruit.io to put
                  together this Tech Stack diagram. To grab a copy all we ask is
                  that you provide your email!
                </p>
                <div
                  style={{ margin: "auto" }}
                  id="__convertKitContainer"
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

TechStackDiagram.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

export default TechStackDiagram;
