import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import TechStackDiagram from "../components/TechStackDiagram";
import Layout from "../components/Layout";

const TechStackDiagramPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name="description" content={post.frontmatter.meta_description} />
      </Helmet>
      <TechStackDiagram
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
    </Layout>
  );
};

TechStackDiagramPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default TechStackDiagramPage;

export const techStackDiagramPageQuery = graphql`
  query TechStackDiagramPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        meta_title
        meta_description
      }
    }
  }
`;
